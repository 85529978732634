<template>
  <div>
    <div v-if="!this.$hasConstruction('IEBDC:SY:XXCX:FCXXCX:SFZJ')">
      <appointment-steps :stepsData="steps" :activeStep="activeStep" :currentLabel="currentLabel">
        <template slot="content">
          <div v-if="activeStep === 0">
            <queryNotice1 @next="next" />
          </div>
          <div v-if="activeStep === 1">
            <queryNotice @next="next" @getipData="getipData" />
          </div>
          <div v-if="activeStep === 2">
            <fillInformation
              @next="next"
              :ipData="ipData"
              @last="last"
              @qszmcdbh="qszmcdbh"
              @cxyt="cxyt"
              @toSmrz="toSmrz"
              @qszmFormData="qszmFormData"
            />
          </div>
          <div v-if="activeStep === 3">
            <div class="block">
              <span>请微信扫描下方二维码进行身份认证</span>
              <el-image :src="qrcode"></el-image>
              <span slot="footer">
                <el-button type="primary" @click="getSmrzStatus">刷新认证状态</el-button>
              </span>
            </div>
          </div>
          <div v-if="activeStep === 4">
            <!-- <queryResult :checkData="resultNumber" :cxyt="cxytNum" @back="back" :qszmData="qszmData" /> -->
            <queryResult
              :checkData="resultNumber"
              :cxmd="qszmFormData.cxyt"
              :totalNum="totalNum"
              :nCurrent="qszmFormData.nCurrent"
              :qszmFormData="qszmFormData"
              :key="index"
              @getListData="getListData"
              :cxjgData="cxjgData"
            />
          </div>
        </template>
      </appointment-steps>
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <no-data v-else :data="dataNo"></no-data>
  </div>
</template>

<script>
import queryNotice from './components/query-notice';
import queryNotice1 from './components/query-notice1';
import fillInformation from './components/fill-information';
import queryResult from './components/query-result';
import hasConstruction from '@iebdc/mixin/hasConstruction';
import zwwLogin from '@iebdc/utils/zwwLogin.js';
import { localDataUser } from '../../utils/local-data';
import authorizationSearch from '@iebdc/api/authorization-search';
import crateQrcode from '@iebdc/plugins/createQRcode.js';
import { initData } from './common';
import { getNodesByParam } from '@iebdc/utils/common';
import { localDataArea } from '@iebdc/utils/local-data.js';
import { getCookie, addCookie, removeCookie } from '@iebdc/utils/cookies';
export default {
  name: 'quanSZMQuery',
  mixins: [hasConstruction],
  components: {
    queryNotice,
    queryNotice1,
    fillInformation,
    queryResult,
  },
  data() {
    return {
      // smrzzt: false,
      qrcode: '',
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '242px',
        imgHeight: '194px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      activeStep: 0,
      steps: [
        {
          title: '法律法规',
        },
        {
          title: '查询须知',
        },
        {
          title: '填写信息',
        },
        {
          title: '人脸识别',
        },
        {
          title: '查询结果',
        },
      ],
      currentLabel: '权属证明',
      resultNumber: null,
      totalNum: null,
      index: null,
      cxytNum: '',
      qszmData: {},
      ipData: {},
      smrzId: '',
      cxjgData: null,
    };
  },
  mounted() {
    // this.smrzzt = false;
    console.log(!this.$hasPermission('IEBDC:DL:TYRZ'), '!this.$hasPermission');
    let userData = localDataUser.get();
    if (!userData) {
      return this.$confirm('', '提示', {
        confirmButtonText: '立马登录',

        cancelButtonText: '暂不登录',

        type: 'warning',
        center: true,
      })
        .then(() => {
          this.goHnlogin();
        })
        .catch();
    }
    console.log("sessionStorage.getItem('flush')", sessionStorage.getItem('flush'));
    if (sessionStorage.getItem('flush') === '1') {
      sessionStorage.removeItem('flush');
      this.$router.go(0);
    }
  },
  methods: {
    toSmrz(resultNumber, totalNum, index, cxjgData) {
      this.resultNumber = resultNumber;
      this.totalNum = totalNum;
      this.index = index;
      this.cxjgData = cxjgData;
      var userData = localDataUser.get();
      console.log(this.$hasPermission('IEBDC:DL:TYRZ'));
      let params = {
        name: userData.name,
        zjh: userData.zjh,
      };
      let _this = this;
      authorizationSearch.visualQuerysave(params).then((res) => {
        if (res.success) {
          this.smrzId = res.data.id;
          let url = this.$decryptByDES(window.CONFIG.APP_INFO.certificationUrl);
          url =
            url +
            '?userName=' +
            this.$encryptByDES(userData.name, 'greatmap') +
            '&idCard=' +
            this.$encryptByDES(userData.zjh, 'greatmap') +
            '&receiveSeqNo=' +
            res.data.id +
            '&type=kshcx';
          console.log(url);
          Promise.all([crateQrcode(url)])
            .then((src) => {
              console.log(src);
              _this.qrcode = src[0];
              // _this.smrzVisible = true;
              _this.timer = setInterval(() => {
                this.getSmrzStatus();
              }, 2000);
              _this.$once('hook:beforeDestroy', () => {
                clearInterval(_this.timer);
                _this.timer = null;
              });
            })
            .catch(() => {
              _this.$message.error('获取二维码失败，请联系管理员');
            });
        }
      });
    },
    getSmrzStatus() {
      authorizationSearch.visualQueryfind({ id: this.smrzId }).then((res) => {
        if (res.success && res.data.rzzt) {
          clearInterval(this.timer);
          this.timer = null;
          this.activeStep = 4;
          // this.submit();
        }
      });
    },
    goHnlogin() {
      //   window.location.href = `${window.CONFIG.UNIFIED_LOGIN}?backUrl=${window.CONFIG.UNIFIED_PAGE}`;
      window.location.href = `${this.$decryptByDES(
        window.CONFIG.LOGIN_TO_ZWW.url
      )}/xjwwrz/login/oauth2login?client_id=${
        window.CONFIG.LOGIN_TO_ZWW.client_id
      }&state=a&response_type=code&scope=user&redirect_uri=${this.$decryptByDES(
        window.CONFIG.LOGIN_TO_ZWW.redirect_uri
      )}`;
    },
    next(activeStep) {
      this.activeStep = activeStep;
    },
    // 上一步
    last(lastStep) {
      this.activeStep = lastStep;
    },
    qszmcdbh(num) {
      // this.resultNumber = num;
    },
    cxyt(number) {
      this.cxytNum = number;
    },
    qszmFormData(data) {
      this.qszmData = Object.assign(data, this.qszmFormData);
      console.log('this.qszmData', this.qszmData);
    },
    getipData(data) {
      this.ipData = data;
    },
    // 返回
    back(backStep) {
      this.activeStep = backStep;
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: flex;
  align-content: center;
  justify-self: start;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  /deep/.el-image {
    width: 258px;
    height: 260px;
    margin: 0 auto;
    border: 1px solid #ffffff;
  }
}
.appointment-warp {
  .wrap-left {
    width: 135px;
    height: auto;
    float: left;
  }
  .wrap-right {
    height: auto;
    margin-left: 135px;
  }
}
/deep/.e-table {
  width: 100%;
  .cell {
    white-space: pre-line !important;
  }
}
</style>
